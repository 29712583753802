/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const holeTextbausteine = /* GraphQL */ `
  query HoleTextbausteine($vorgangId: String!) {
    holeTextbausteine(vorgangId: $vorgangId)
  }
`;
export const holeTextbausteineZuGutachtenart = /* GraphQL */ `
  query HoleTextbausteineZuGutachtenart(
    $gutachtenart: String!
    $mandant: String!
  ) {
    holeTextbausteineZuGutachtenart(
      gutachtenart: $gutachtenart
      mandant: $mandant
    )
  }
`;
export const holeTextbausteineZuMandant = /* GraphQL */ `
  query HoleTextbausteineZuMandant($mandant: String!) {
    holeTextbausteineZuMandant(mandant: $mandant)
  }
`;
export const holeDatZugangsdaten = /* GraphQL */ `
  query HoleDatZugangsdaten($mandant: String) {
    holeDatZugangsdaten(mandant: $mandant) {
      ok
      data
      error
    }
  }
`;
export const testeDatZugangsdaten = /* GraphQL */ `
  query TesteDatZugangsdaten($datZugangsdaten: AWSJSON!) {
    testeDatZugangsdaten(datZugangsdaten: $datZugangsdaten) {
      ok
      data {
        valuateExpert
        calculateExpert
      }
      error
    }
  }
`;
export const generiereToken = /* GraphQL */ `
  query GeneriereToken($mandant: String!) {
    generiereToken(mandant: $mandant) {
      valuateExpertToken
      calculateExpertToken
      fahrzeugauswahlToken
      rentalPricesToken
    }
  }
`;
export const holeMandanten = /* GraphQL */ `
  query HoleMandanten {
    holeMandanten
  }
`;
export const holeMandant = /* GraphQL */ `
  query HoleMandant($mandant: String!) {
    holeMandant(mandant: $mandant) {
      id
      hisEmail
      honorartabelle {
        schadenhoehe
        grundhonorar
      }
      updatedAt
      createdAt
    }
  }
`;
export const holeBenutzerliste = /* GraphQL */ `
  query HoleBenutzerliste($mandant: String!) {
    holeBenutzerliste(mandant: $mandant) {
      id
      sub
      kuerzel
      mandant
      given_name
      family_name
      email
    }
  }
`;
export const holeBenutzerlisteInGruppe = /* GraphQL */ `
  query HoleBenutzerlisteInGruppe($gruppe: String!, $mandant: String) {
    holeBenutzerlisteInGruppe(gruppe: $gruppe, mandant: $mandant) {
      id
      sub
      kuerzel
      mandant
      given_name
      family_name
      email
    }
  }
`;
export const fuegeUserZuCognitoGruppeHinzu = /* GraphQL */ `
  query FuegeUserZuCognitoGruppeHinzu(
    $gruppe: String!
    $userId: String!
    $mandant: String!
  ) {
    fuegeUserZuCognitoGruppeHinzu(
      gruppe: $gruppe
      userId: $userId
      mandant: $mandant
    )
  }
`;
export const entferneUserAusCognitoGruppe = /* GraphQL */ `
  query EntferneUserAusCognitoGruppe(
    $gruppe: String!
    $userId: String!
    $mandant: String!
  ) {
    entferneUserAusCognitoGruppe(
      gruppe: $gruppe
      userId: $userId
      mandant: $mandant
    )
  }
`;
export const fuegeBenutzerZuCognitoHinzu = /* GraphQL */ `
  query FuegeBenutzerZuCognitoHinzu(
    $id: String!
    $family_name: String!
    $given_name: String!
    $kuerzel: String!
    $mandant: String!
    $email: String!
  ) {
    fuegeBenutzerZuCognitoHinzu(
      id: $id
      family_name: $family_name
      given_name: $given_name
      kuerzel: $kuerzel
      mandant: $mandant
      email: $email
    )
  }
`;
export const entferneBenutzerAusCognito = /* GraphQL */ `
  query EntferneBenutzerAusCognito($userId: String!) {
    entferneBenutzerAusCognito(userId: $userId)
  }
`;
export const setzePasswortFuerBenutzerInCognitoZurueck = /* GraphQL */ `
  query SetzePasswortFuerBenutzerInCognitoZurueck($userId: String!) {
    setzePasswortFuerBenutzerInCognitoZurueck(userId: $userId)
  }
`;
export const aktualisiereBenutzerInCognito = /* GraphQL */ `
  query AktualisiereBenutzerInCognito(
    $id: String!
    $family_name: String
    $given_name: String
    $mandant: String
    $email: String
  ) {
    aktualisiereBenutzerInCognito(
      id: $id
      family_name: $family_name
      given_name: $given_name
      mandant: $mandant
      email: $email
    )
  }
`;
export const holeLackarten = /* GraphQL */ `
  query HoleLackarten(
    $manufacturer: Int!
    $vehicleType: Int!
    $baseModel: Int!
    $mandant: String!
    $paintMethod: String!
  ) {
    holeLackarten(
      manufacturer: $manufacturer
      vehicleType: $vehicleType
      baseModel: $baseModel
      mandant: $mandant
      paintMethod: $paintMethod
    ) {
      ok
      data {
        description
        key
      }
      error
    }
  }
`;
export const sucheVorgaenge = /* GraphQL */ `
  query SucheVorgaenge(
    $suchbegriff: String!
    $vonDatum: AWSDate!
    $bisDatum: AWSDate!
    $status: [String]
    $geloescht: Boolean
    $from: Int
    $limit: Int
    $sortBy: String
    $order: String
  ) {
    sucheVorgaenge(
      suchbegriff: $suchbegriff
      vonDatum: $vonDatum
      bisDatum: $bisDatum
      status: $status
      geloescht: $geloescht
      from: $from
      limit: $limit
      sortBy: $sortBy
      order: $order
    ) {
      items {
        id
        mandant
        vorgangsnummer
        vorgangsnummerRaw
        status
        userId
        bearbeiterVorname
        bearbeiterNachname
        gruppen
        ehemaligeBearbeiter
        gutachtenart
        auftraggeberFirmenname
        auftraggeberNachname
        auftraggeberAnzeigename
        fahrzeughalterVorname
        fahrzeughalterNachname
        kennzeichen
        fahrgestellnummer
        fotos {
          thumbnailkey
        }
        rechnungsnummern
        verwaltungscheckbox
        geloescht
        updatedAt
        createdAt
      }
      total
      totalAktive
      totalQualitaetssicherung
      totalBeweissicherung
      totalMahnwesen
      totalGeschlossen
      fehler
    }
  }
`;
export const suchePersonen = /* GraphQL */ `
  query SuchePersonen(
    $suchbegriff: String!
    $mandant: String!
    $typ: String!
    $from: Int
    $limit: Int
    $sortBy: String
    $order: String
  ) {
    suchePersonen(
      suchbegriff: $suchbegriff
      mandant: $mandant
      typ: $typ
      from: $from
      limit: $limit
      sortBy: $sortBy
      order: $order
    ) {
      items {
        id
        userId
        gruppen
        mandant
        weitereBearbeiter
        typ
        firmenname
        anrede
        nachname
        vorname
        strasse
        plz
        ort
        geburtsdatum
        nationalitaet
        telefon
        email
        updatedAt
        createdAt
      }
      total
      fehler
    }
  }
`;
export const sucheUnternehmen = /* GraphQL */ `
  query SucheUnternehmen(
    $suchbegriff: String!
    $mandant: String
    $typ: String!
    $from: Int
    $limit: Int
    $sortBy: String
    $order: String
  ) {
    sucheUnternehmen(
      suchbegriff: $suchbegriff
      mandant: $mandant
      typ: $typ
      from: $from
      limit: $limit
      sortBy: $sortBy
      order: $order
    ) {
      items {
        id
        userId
        gruppen
        typ
        firmenname
        anrede
        nachname
        vorname
        strasse
        plz
        ort
        telefon
        email
        updatedAt
        createdAt
      }
      total
      fehler
    }
  }
`;
export const sucheGutachten = /* GraphQL */ `
  query SucheGutachten($vorgangId: String!, $fahrgestellnummer: String!) {
    sucheGutachten(
      vorgangId: $vorgangId
      fahrgestellnummer: $fahrgestellnummer
    ) {
      items {
        gutachtennummer
        url
        erstellungsdatum
      }
    }
  }
`;
export const getMandant = /* GraphQL */ `
  query GetMandant($id: ID!) {
    getMandant(id: $id) {
      id
      hisEmail
      honorartabelle {
        schadenhoehe
        grundhonorar
      }
      updatedAt
      createdAt
    }
  }
`;
export const listMandanten = /* GraphQL */ `
  query ListMandanten(
    $filter: ModelMandantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMandanten(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        hisEmail
        honorartabelle {
          schadenhoehe
          grundhonorar
        }
        updatedAt
        createdAt
      }
      nextToken
    }
  }
`;
export const listUserRechte = /* GraphQL */ `
  query ListUserRechte(
    $filter: ModelUserRechteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserRechte(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        mandantenrechte {
          mandantenKuerzel
          rechte
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listTextbausteine = /* GraphQL */ `
  query ListTextbausteine(
    $filter: ModelTextbausteinFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTextbausteine(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        mandant
        gutachtenart
        ueberschrift
        text
        regel
        reihenfolge
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const eventsByVorgangId = /* GraphQL */ `
  query EventsByVorgangId(
    $vorgangId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventsByVorgangId(
      vorgangId: $vorgangId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        bearbeiter {
          id
          sub
          kuerzel
          mandant
          given_name
          family_name
          email
        }
        gruppen
        vorgangId
        type
        payload
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchPeople = /* GraphQL */ `
  query SearchPeople(
    $filter: SearchablePersonFilterInput
    $sort: [SearchablePersonSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchablePersonAggregationInput]
  ) {
    searchPeople(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        userId
        gruppen
        mandant
        weitereBearbeiter
        typ
        firmenname
        anrede
        nachname
        vorname
        strasse
        plz
        ort
        geburtsdatum
        nationalitaet
        telefon
        email
        updatedAt
        createdAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const listUnternehmen = /* GraphQL */ `
  query ListUnternehmen(
    $filter: ModelUnternehmenFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUnternehmen(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        gruppen
        typ
        firmenname
        anrede
        nachname
        vorname
        strasse
        plz
        ort
        telefon
        email
        updatedAt
        createdAt
      }
      nextToken
    }
  }
`;
export const searchUnternehmen = /* GraphQL */ `
  query SearchUnternehmen(
    $filter: SearchableUnternehmenFilterInput
    $sort: [SearchableUnternehmenSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableUnternehmenAggregationInput]
  ) {
    searchUnternehmen(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        userId
        gruppen
        typ
        firmenname
        anrede
        nachname
        vorname
        strasse
        plz
        ort
        telefon
        email
        updatedAt
        createdAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const listVorgaengeDashboard = /* GraphQL */ `
  query ListVorgaengeDashboard(
    $filter: ModelVorgangDashboardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVorgaengeDashboard(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        mandant
        vorgangsnummer
        vorgangsnummerRaw
        status
        userId
        bearbeiterVorname
        bearbeiterNachname
        gruppen
        ehemaligeBearbeiter
        gutachtenart
        auftraggeberFirmenname
        auftraggeberNachname
        auftraggeberAnzeigename
        fahrzeughalterVorname
        fahrzeughalterNachname
        kennzeichen
        fahrgestellnummer
        fotos {
          thumbnailkey
        }
        rechnungsnummern
        verwaltungscheckbox
        geloescht
        updatedAt
        createdAt
      }
      nextToken
    }
  }
`;
export const searchVorgangDashboards = /* GraphQL */ `
  query SearchVorgangDashboards(
    $filter: SearchableVorgangDashboardFilterInput
    $sort: [SearchableVorgangDashboardSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableVorgangDashboardAggregationInput]
  ) {
    searchVorgangDashboards(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        mandant
        vorgangsnummer
        vorgangsnummerRaw
        status
        userId
        bearbeiterVorname
        bearbeiterNachname
        gruppen
        ehemaligeBearbeiter
        gutachtenart
        auftraggeberFirmenname
        auftraggeberNachname
        auftraggeberAnzeigename
        fahrzeughalterVorname
        fahrzeughalterNachname
        kennzeichen
        fahrgestellnummer
        fotos {
          thumbnailkey
        }
        rechnungsnummern
        verwaltungscheckbox
        geloescht
        updatedAt
        createdAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getVorgang = /* GraphQL */ `
  query GetVorgang($id: ID!) {
    getVorgang(id: $id) {
      id
      vorgangsnummer
      mandant
      status
      statusLetzteAenderung
      aufgaben {
        finaleDatenerfassung
        bildbearbeitung
        kalkulationserstellung
        wiederbeschaffungswertUndSchadensumfang
        merkantilerMinderwert
        restwert
        wertverbesserung
        textbearbeitung
      }
      userId
      bearbeiter {
        id
        sub
        kuerzel
        mandant
        given_name
        family_name
        email
      }
      gruppen
      ehemaligeBearbeiter
      gutachtenart
      besichtigungen {
        items {
          id
          vorgangId
          strasse
          plz
          ort
          vermittlerId
          vermittler {
            id
            userId
            gruppen
            mandant
            weitereBearbeiter
            typ
            firmenname
            anrede
            nachname
            vorname
            strasse
            plz
            ort
            geburtsdatum
            nationalitaet
            telefon
            email
            updatedAt
            createdAt
          }
          besichtigungstermin
          demontierterZustand
          userId
          ehemaligeBearbeiter
          gruppen
          updatedAt
          createdAt
        }
        nextToken
      }
      auftraggeberId
      auftraggeber {
        id
        userId
        gruppen
        mandant
        weitereBearbeiter
        typ
        firmenname
        anrede
        nachname
        vorname
        strasse
        plz
        ort
        geburtsdatum
        nationalitaet
        telefon
        email
        updatedAt
        createdAt
      }
      auftraggeberRechtsschutzVorhanden
      auftraggeberVorsteuerabzugsberechtigt
      auftraggeberPostversand
      auftraggeberVip
      fahrzeughalterId
      fahrzeughalter {
        id
        userId
        gruppen
        mandant
        weitereBearbeiter
        typ
        firmenname
        anrede
        nachname
        vorname
        strasse
        plz
        ort
        geburtsdatum
        nationalitaet
        telefon
        email
        updatedAt
        createdAt
      }
      fahrzeughalterIstAuftraggeber
      fahrzeughalterRechtsschutzVorhanden
      fahrzeugdaten {
        fahrgestellnummer
        kilometerleistung
        kilometerleistungGeschaetzt
        kilometerleistungAngegeben
        land
        kennzeichen
        erstesZulassungsdatum
        letztesZulassungsdatum
        naechsteHauptuntersuchung
        anzahlVorhalter
        scheckheftgepflegt
        regelbesteuert
        bereifung
        fahrzeugtyp
        achsen {
          achsenPosition
          reifengroesse
          typ
          hersteller
          reifen {
            position
            profiltiefe
          }
        }
        allgemeinzustand
        altschaden {
          schadenVorhandenAuswahl
          schadenbereiche {
            vorneLinks
            vorneMittig
            vorneRechts
            tuerVorneLinks
            tuerVorneRechts
            tuerHintenLinks
            tuerHintenRechts
            dach
            hintenLinks
            hintenMittig
            hintenRechts
          }
          schaeden {
            bauteil
            beschaedigung
          }
        }
        reparierteVorschaeden {
          schadenVorhandenAuswahl
          schadenbereiche {
            vorneLinks
            vorneMittig
            vorneRechts
            tuerVorneLinks
            tuerVorneRechts
            tuerHintenLinks
            tuerHintenRechts
            dach
            hintenLinks
            hintenMittig
            hintenRechts
          }
          vorschaeden {
            bauteil
            sachFachgerecht
            lackschichtendickenmessung
            reparaturart
          }
        }
        bemerkungen
        zulassungsbescheinigung
        reifenzustand
        mietwagenklasse
        nutzungsausfallKlasse
        nutzungsausfallKlasseOhneFahrzeugAlter
        nutzungsausfallOhneAltersbezug
        phantomkalkulation
        anzahlSitze
        anzahlTueren
        antriebsart
        aufbauart
        hubraum
        leistungKw
        farbe
        container
        containerName
        herstellername
        haupttypname
        untertypname
        fahrzeugartname
        serienausstattung
        sonderausstattung
        zusatzausstattung
        reparierteSchadenbereiche {
          baugruppe
          beschreibung
          reparaturart
        }
        lackPositionen {
          beschreibung
          lackstufe
        }
        arbeitsPositionen {
          beschreibung
          reparaturart
        }
        materialPositionen {
          beschreibung
          preisProEinheit
        }
      }
      bewertung {
        wertverbesserung
        restwert
        angebote {
          firmenname
          strasse
          plz
          ort
          telefon
          wert
          key
        }
        merkantilerMinderwert
        neupreis
        wiederbeschaffungswert
        reparaturkosten
        veraeusserungswert
        fahrzeugalter
        schadenumfang
        faktorMarktgaengigkeit
        faktorVorschaeden
        lohnkosten
        materialkosten
        bemerkung
        wiederbeschaffungsdauer
        reparaturdauer
        korrekturWiederbeschaffungswert
        versandinformation {
          versanddatum
          empfaenger
        }
      }
      stundenverrechnungssaetze {
        typ
        mechanik
        karosserie
        lackierung
        elektrik
        aufschlagLackmaterial
        aufschlagErsatzteil
        verbringungszeit
        lackart
        inklusiveMaterial
        lacquerMethod
      }
      unfallgegnerId
      unfallgegner {
        id
        userId
        gruppen
        mandant
        weitereBearbeiter
        typ
        firmenname
        anrede
        nachname
        vorname
        strasse
        plz
        ort
        geburtsdatum
        nationalitaet
        telefon
        email
        updatedAt
        createdAt
      }
      unfalldaten {
        land
        unfallort
        datum
        kennzeichen
        fahrzeughersteller
        fahrzeugart
        sonstigeFahrzeugart
        versicherungId
        versicherungsnummer
        schadennummer
        selbstbeteiligung
      }
      dat {
        dossierId
        contractId
        datECode
        letzteAktualisierung
        calculationDocument {
          key
          dateiname
          uploaddatum
        }
        vehicleEvaluationDocument {
          key
          dateiname
          uploaddatum
        }
        arbeitswerte
        wertverbesserung
        reparaturkostenBrutto
        lohnkosten
        materialkosten
        anzahlSitze
        anzahlTueren
        antriebsart
        aufbauart
        hubraum
        leistungKw
        farbe
        kennzeichen
        kilometerleistung
        fahrgestellnummer
        wiederbeschaffungswertBrutto
        listenneupreisBrutto
        listenausstattungspreisBrutto
        neupreisBrutto
        vehicleType
        manufacturer
        baseModel
      }
      reparaturdauer {
        arbeitswerteProStunde
        materialbeschaffungsdauer
      }
      unterschriften {
        dsgvo {
          key
          datum
          unterzeichner
        }
        abtretungserklaerung {
          key
          datum
          unterzeichner
        }
        his {
          key
          datum
          unterzeichner
        }
        schlussbemerkung {
          key
          datum
          unterzeichner
        }
        auftragsbestaetigung {
          key
          datum
          unterzeichner
        }
      }
      fotos {
        key
        dateiname
        kategorien
        typ
        thumbnailname
        thumbnailkey
        uploaddatum
        beschreibung
      }
      dateien {
        key
        dateiname
        kategorien
        typ
        uploaddatum
        restwertangebot
        wert
      }
      bemerkungenZumSchaden {
        fahrbereit
        verkehrssicherNachNotreparatur
        verkehrssicher
        pruefarbeitenErforderlich
        achsvermessungErforderlich
        achsvermessungDurchgefuehrt
        achsvermessungMitSchadenfeststellung
        lenkgetriebeErneuern
        achsweiseErneuerungDerReifen
        achsweiseErneuerungDerStossdaempfer
        karosserievermessungErforderlich
        richtbankErforderlich
        beilackierungNotwendig
        anhaengerkupplungAusSicherheitsgruendenErneuern
        schadenhergang
        plausibilitaet
        factoring
        finanzierungMoeglich
        reparaturnachweisAuswahl
        probefahrtErforderlich
        fehlerspeicherAuslesen
        bemerkungen
      }
      gutachten {
        gutachtennummer
        key
        dateiname
        keyDruckversion
        dateinameDruckversion
        erstellungsdatum
        generierungsstatus
        generierungsfehler
      }
      rechtsanwaltId
      rechtsanwalt {
        id
        userId
        gruppen
        typ
        firmenname
        anrede
        nachname
        vorname
        strasse
        plz
        ort
        telefon
        email
        updatedAt
        createdAt
      }
      werkstattId
      werkstatt {
        id
        userId
        gruppen
        typ
        firmenname
        anrede
        nachname
        vorname
        strasse
        plz
        ort
        telefon
        email
        updatedAt
        createdAt
      }
      versicherungId
      versicherung {
        id
        userId
        gruppen
        typ
        firmenname
        anrede
        nachname
        vorname
        strasse
        plz
        ort
        telefon
        email
        updatedAt
        createdAt
      }
      entsorgen
      vorgangTextbausteine {
        ueberschrift
        text
        regel
        reihenfolge
        disabled
        id
      }
      wurdeGutachtenVersandt
      his {
        datei {
          key
          dateiname
          uploaddatum
        }
        wurdeHisVersand
        versandDatum
      }
      zugehoerigeGutachtenId
      zugehoerigeGutachtenNummer
      rechnungsnummern
      versandinformation {
        versanddatum
        empfaenger
      }
      zahlungseingang {
        id
        jahr
        importDatum
        rechnungsnummer
        betrag
        bezahldatum
        status
        name
        beschreibung
        gutachtennummer
      }
      verwaltungscheckbox
      geloescht
      updatedAt
      createdAt
    }
  }
`;
export const listVorgaenge = /* GraphQL */ `
  query ListVorgaenge(
    $filter: ModelVorgangFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVorgaenge(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        vorgangsnummer
        mandant
        status
        statusLetzteAenderung
        aufgaben {
          finaleDatenerfassung
          bildbearbeitung
          kalkulationserstellung
          wiederbeschaffungswertUndSchadensumfang
          merkantilerMinderwert
          restwert
          wertverbesserung
          textbearbeitung
        }
        userId
        bearbeiter {
          id
          sub
          kuerzel
          mandant
          given_name
          family_name
          email
        }
        gruppen
        ehemaligeBearbeiter
        gutachtenart
        besichtigungen {
          items {
            id
            vorgangId
            strasse
            plz
            ort
            vermittlerId
            vermittler {
              id
              userId
              gruppen
              mandant
              weitereBearbeiter
              typ
              firmenname
              anrede
              nachname
              vorname
              strasse
              plz
              ort
              geburtsdatum
              nationalitaet
              telefon
              email
              updatedAt
              createdAt
            }
            besichtigungstermin
            demontierterZustand
            userId
            ehemaligeBearbeiter
            gruppen
            updatedAt
            createdAt
          }
          nextToken
        }
        auftraggeberId
        auftraggeber {
          id
          userId
          gruppen
          mandant
          weitereBearbeiter
          typ
          firmenname
          anrede
          nachname
          vorname
          strasse
          plz
          ort
          geburtsdatum
          nationalitaet
          telefon
          email
          updatedAt
          createdAt
        }
        auftraggeberRechtsschutzVorhanden
        auftraggeberVorsteuerabzugsberechtigt
        auftraggeberPostversand
        auftraggeberVip
        fahrzeughalterId
        fahrzeughalter {
          id
          userId
          gruppen
          mandant
          weitereBearbeiter
          typ
          firmenname
          anrede
          nachname
          vorname
          strasse
          plz
          ort
          geburtsdatum
          nationalitaet
          telefon
          email
          updatedAt
          createdAt
        }
        fahrzeughalterIstAuftraggeber
        fahrzeughalterRechtsschutzVorhanden
        fahrzeugdaten {
          fahrgestellnummer
          kilometerleistung
          kilometerleistungGeschaetzt
          kilometerleistungAngegeben
          land
          kennzeichen
          erstesZulassungsdatum
          letztesZulassungsdatum
          naechsteHauptuntersuchung
          anzahlVorhalter
          scheckheftgepflegt
          regelbesteuert
          bereifung
          fahrzeugtyp
          achsen {
            achsenPosition
            reifengroesse
            typ
            hersteller
            reifen {
              position
              profiltiefe
            }
          }
          allgemeinzustand
          altschaden {
            schadenVorhandenAuswahl
            schadenbereiche {
              vorneLinks
              vorneMittig
              vorneRechts
              tuerVorneLinks
              tuerVorneRechts
              tuerHintenLinks
              tuerHintenRechts
              dach
              hintenLinks
              hintenMittig
              hintenRechts
            }
            schaeden {
              bauteil
              beschaedigung
            }
          }
          reparierteVorschaeden {
            schadenVorhandenAuswahl
            schadenbereiche {
              vorneLinks
              vorneMittig
              vorneRechts
              tuerVorneLinks
              tuerVorneRechts
              tuerHintenLinks
              tuerHintenRechts
              dach
              hintenLinks
              hintenMittig
              hintenRechts
            }
            vorschaeden {
              bauteil
              sachFachgerecht
              lackschichtendickenmessung
              reparaturart
            }
          }
          bemerkungen
          zulassungsbescheinigung
          reifenzustand
          mietwagenklasse
          nutzungsausfallKlasse
          nutzungsausfallKlasseOhneFahrzeugAlter
          nutzungsausfallOhneAltersbezug
          phantomkalkulation
          anzahlSitze
          anzahlTueren
          antriebsart
          aufbauart
          hubraum
          leistungKw
          farbe
          container
          containerName
          herstellername
          haupttypname
          untertypname
          fahrzeugartname
          serienausstattung
          sonderausstattung
          zusatzausstattung
          reparierteSchadenbereiche {
            baugruppe
            beschreibung
            reparaturart
          }
          lackPositionen {
            beschreibung
            lackstufe
          }
          arbeitsPositionen {
            beschreibung
            reparaturart
          }
          materialPositionen {
            beschreibung
            preisProEinheit
          }
        }
        bewertung {
          wertverbesserung
          restwert
          angebote {
            firmenname
            strasse
            plz
            ort
            telefon
            wert
            key
          }
          merkantilerMinderwert
          neupreis
          wiederbeschaffungswert
          reparaturkosten
          veraeusserungswert
          fahrzeugalter
          schadenumfang
          faktorMarktgaengigkeit
          faktorVorschaeden
          lohnkosten
          materialkosten
          bemerkung
          wiederbeschaffungsdauer
          reparaturdauer
          korrekturWiederbeschaffungswert
          versandinformation {
            versanddatum
            empfaenger
          }
        }
        stundenverrechnungssaetze {
          typ
          mechanik
          karosserie
          lackierung
          elektrik
          aufschlagLackmaterial
          aufschlagErsatzteil
          verbringungszeit
          lackart
          inklusiveMaterial
          lacquerMethod
        }
        unfallgegnerId
        unfallgegner {
          id
          userId
          gruppen
          mandant
          weitereBearbeiter
          typ
          firmenname
          anrede
          nachname
          vorname
          strasse
          plz
          ort
          geburtsdatum
          nationalitaet
          telefon
          email
          updatedAt
          createdAt
        }
        unfalldaten {
          land
          unfallort
          datum
          kennzeichen
          fahrzeughersteller
          fahrzeugart
          sonstigeFahrzeugart
          versicherungId
          versicherungsnummer
          schadennummer
          selbstbeteiligung
        }
        dat {
          dossierId
          contractId
          datECode
          letzteAktualisierung
          calculationDocument {
            key
            dateiname
            uploaddatum
          }
          vehicleEvaluationDocument {
            key
            dateiname
            uploaddatum
          }
          arbeitswerte
          wertverbesserung
          reparaturkostenBrutto
          lohnkosten
          materialkosten
          anzahlSitze
          anzahlTueren
          antriebsart
          aufbauart
          hubraum
          leistungKw
          farbe
          kennzeichen
          kilometerleistung
          fahrgestellnummer
          wiederbeschaffungswertBrutto
          listenneupreisBrutto
          listenausstattungspreisBrutto
          neupreisBrutto
          vehicleType
          manufacturer
          baseModel
        }
        reparaturdauer {
          arbeitswerteProStunde
          materialbeschaffungsdauer
        }
        unterschriften {
          dsgvo {
            key
            datum
            unterzeichner
          }
          abtretungserklaerung {
            key
            datum
            unterzeichner
          }
          his {
            key
            datum
            unterzeichner
          }
          schlussbemerkung {
            key
            datum
            unterzeichner
          }
          auftragsbestaetigung {
            key
            datum
            unterzeichner
          }
        }
        fotos {
          key
          dateiname
          kategorien
          typ
          thumbnailname
          thumbnailkey
          uploaddatum
          beschreibung
        }
        dateien {
          key
          dateiname
          kategorien
          typ
          uploaddatum
          restwertangebot
          wert
        }
        bemerkungenZumSchaden {
          fahrbereit
          verkehrssicherNachNotreparatur
          verkehrssicher
          pruefarbeitenErforderlich
          achsvermessungErforderlich
          achsvermessungDurchgefuehrt
          achsvermessungMitSchadenfeststellung
          lenkgetriebeErneuern
          achsweiseErneuerungDerReifen
          achsweiseErneuerungDerStossdaempfer
          karosserievermessungErforderlich
          richtbankErforderlich
          beilackierungNotwendig
          anhaengerkupplungAusSicherheitsgruendenErneuern
          schadenhergang
          plausibilitaet
          factoring
          finanzierungMoeglich
          reparaturnachweisAuswahl
          probefahrtErforderlich
          fehlerspeicherAuslesen
          bemerkungen
        }
        gutachten {
          gutachtennummer
          key
          dateiname
          keyDruckversion
          dateinameDruckversion
          erstellungsdatum
          generierungsstatus
          generierungsfehler
        }
        rechtsanwaltId
        rechtsanwalt {
          id
          userId
          gruppen
          typ
          firmenname
          anrede
          nachname
          vorname
          strasse
          plz
          ort
          telefon
          email
          updatedAt
          createdAt
        }
        werkstattId
        werkstatt {
          id
          userId
          gruppen
          typ
          firmenname
          anrede
          nachname
          vorname
          strasse
          plz
          ort
          telefon
          email
          updatedAt
          createdAt
        }
        versicherungId
        versicherung {
          id
          userId
          gruppen
          typ
          firmenname
          anrede
          nachname
          vorname
          strasse
          plz
          ort
          telefon
          email
          updatedAt
          createdAt
        }
        entsorgen
        vorgangTextbausteine {
          ueberschrift
          text
          regel
          reihenfolge
          disabled
          id
        }
        wurdeGutachtenVersandt
        his {
          datei {
            key
            dateiname
            uploaddatum
          }
          wurdeHisVersand
          versandDatum
        }
        zugehoerigeGutachtenId
        zugehoerigeGutachtenNummer
        rechnungsnummern
        versandinformation {
          versanddatum
          empfaenger
        }
        zahlungseingang {
          id
          jahr
          importDatum
          rechnungsnummer
          betrag
          bezahldatum
          status
          name
          beschreibung
          gutachtennummer
        }
        verwaltungscheckbox
        geloescht
        updatedAt
        createdAt
      }
      nextToken
    }
  }
`;
export const getBesichtigung = /* GraphQL */ `
  query GetBesichtigung($id: ID!) {
    getBesichtigung(id: $id) {
      id
      vorgangId
      strasse
      plz
      ort
      vermittlerId
      vermittler {
        id
        userId
        gruppen
        mandant
        weitereBearbeiter
        typ
        firmenname
        anrede
        nachname
        vorname
        strasse
        plz
        ort
        geburtsdatum
        nationalitaet
        telefon
        email
        updatedAt
        createdAt
      }
      besichtigungstermin
      demontierterZustand
      userId
      ehemaligeBearbeiter
      gruppen
      updatedAt
      createdAt
    }
  }
`;
export const existiertMandant = /* GraphQL */ `
  query ExistiertMandant($mandant: String!) {
    existiertMandant(mandant: $mandant)
  }
`;
