import { Dateityp, EmailEmpfaengerTyp, Generierungsstatus, Gutachtenart, PersonenTyp, Status, UnternehmenTyp, Unterzeichner } from './shared/constants';

// type d = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 0;
// type YYYY = `19${d}${d}` | `20${d}${d}`;
// type oneToNine = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
// type MM = `0${oneToNine}` | `1${0 | 1 | 2}`;
// type DD = `${0}${oneToNine}` | `${1 | 2}${d}` | `3${0 | 1}`;
// export type Datum = `${YYYY}-${MM}-${DD}` | null;
/**
 * Datumsformat YYYY-MM-DD. Keine Überprüfung auf Schaltjahre oder korrekte Monatslänge.
 */
export type Datum = string | null;

export type Aufgaben = {
  finaleDatenerfassung?: boolean | null;
  bildbearbeitung?: boolean | null;
  kalkulationserstellung?: boolean | null;
  wiederbeschaffungswertUndSchadensumfang?: boolean | null;
  merkantilerMinderwert?: boolean | null;
  restwert?: boolean | null;
  wertverbesserung?: boolean | null;
  textbearbeitung?: boolean | null;
};

// Cognito Custom Attributes
export type BenutzerAttribute = {
  mandant: string;
  kuerzel: string;
};

/**
 * @deprecated
 */
export type deprecated_Benutzer = {
  id?: string | null;
  sub?: string | null;
  given_name?: string | null;
  family_name?: string | null;
  email?: string | null;
} & BenutzerAttribute;

export type Benutzer = {
  readonly username: string;
  readonly sub: string;
  vorname: string;
  nachname: string;
  email: string;
  readonly mandant: string;
  readonly kuerzel: string;
  readonly gruppen: string[];
};

export type PersistedEntity = {
  id?: string;
  createdAt?: string;
  updatedAt?: string;
};

export type Benutzerprofil = {
  strasse?: string | null;
  plz?: string | null;
  ort?: string | null;
  land?: string | null;
  position?: [number, number] | null;
} & PersistedEntity;

export type Person = {
  createdAt?: string;
  updatedAt?: string;
  userId?: string | null;
  gruppen?: Array<string>;
  mandant?: string | null;
  weitereBearbeiter: Array<string>;
  typ: Array<PersonenTyp>;
  firmenname?: string | null;
  anrede?: string | null;
  nachname?: string | null;
  vorname?: string | null;
  strasse?: string | null;
  plz?: string | null;
  ort?: string | null;
  geburtsdatum?: Datum;
  nationalitaet?: string | null;
  telefon: Array<string>;
  email: Array<string>;
} & PersistedEntity;

export type Besichtigung = {
  id?: string | null;
  vorgangId: string;
  strasse?: string | null;
  plz?: string | null;
  ort?: string | null;
  vermittlerId?: string | null;
  vermittler?: Person | null;
  besichtigungstermin?: string | null;
  demontierterZustand?: boolean | null;
  userId?: string;
  ehemaligeBearbeiter?: string[];
  gruppen?: string[];
};

export type BesichtigungenConnection = { items: Besichtigung[]; nextToken?: string | null };

export type Fahrzeughalter = {
  personId?: string | null;
  rechtsschutzVorhanden?: boolean | null;
  istAuftraggeber?: boolean | null;
};

export type Reifen = {
  position: string;
  profiltiefe: string | null;
};

export type Achse = {
  achsenPosition: string;
  reifengroesse: string | null;
  typ: string;
  hersteller: string | null;
  reifen: Reifen[];
};

export type Schadenbereiche = {
  vorneLinks?: boolean | null;
  vorneMittig?: boolean | null;
  vorneRechts?: boolean | null;
  tuerVorneLinks?: boolean | null;
  tuerVorneRechts?: boolean | null;
  tuerHintenLinks?: boolean | null;
  tuerHintenRechts?: boolean | null;
  dach?: boolean | null;
  hintenLinks?: boolean | null;
  hintenMittig?: boolean | null;
  hintenRechts?: boolean | null;
};

export type Schaden = {
  bauteil?: string | null;
  beschaedigung?: string | null;
};

export type Altschaden = {
  schadenVorhandenAuswahl?: string | null;
  schadenbereiche?: Schadenbereiche;
  schaeden?: Array<Schaden | null> | null;
};

export type Vorschaden = {
  bauteil?: string | null;
  sachFachgerecht?: string | null;
  lackschichtendickenmessung?: string | null;
  reparaturart?: string | null;
};

export enum ReparierteVorschadenEnum {
  KEINE_REPARIERTEN_VORSCHAEDEN_MITGETEILT_UND_VORGEFUNDEN = 'KEINE_REPARIERTEN_VORSCHAEDEN_MITGETEILT_UND_VORGEFUNDEN',
  REPARIERTE_VORSCHAEDEN_VORHANDEN = 'REPARIERTE_VORSCHAEDEN_VORHANDEN',
  LACKSCHICHTENDICKENMESSUNG_DURCHGEFUEHRT_UND_KEINE_REPARIERTEN_VORSCHAEDEN = 'LACKSCHICHTENDICKENMESSUNG_DURCHGEFUEHRT_UND_KEINE_REPARIERTEN_VORSCHAEDEN'
}

export type ReparierteVorschaden = {
  schadenVorhandenAuswahl?: ReparierteVorschadenEnum | null;
  schadenbereiche?: Schadenbereiche;
  vorschaeden?: Array<Vorschaden> | null;
};

export type ReparierterSchadenbereich = {
  baugruppe?: string | null;
  beschreibung?: string | null;
  reparaturart?: string | null;
};

export type LackPosition = {
  beschreibung?: string | null;
  lackstufe?: string | null;
};

export type ArbeitsPosition = {
  beschreibung?: string | null;
  reparaturart?: string | null;
};

export type MaterialPosition = {
  beschreibung?: string | null;
  preisProEinheit?: number | null;
};

export type Angebot = {
  firmenname?: string;
  strasse?: string;
  plz?: string;
  ort?: string;
  telefon?: string;
  wert?: string;
  key?: string | null;
};

export type Versandinformation = {
  versanddatum?: string | null;
  empfaenger?: Array<string | null> | null;
};

export type Bewertung = {
  wertverbesserung?: string | null;
  restwert?: string | null;
  angebote?: Array<Angebot> | null;
  merkantilerMinderwert?: string | null;
  neupreis?: string | null;
  wiederbeschaffungswert?: string | null;
  reparaturkosten?: string | null;
  veraeusserungswert?: string | null;
  fahrzeugalter?: string | null;
  schadenumfang?: string | null;
  faktorMarktgaengigkeit?: string | null;
  faktorVorschaeden?: string | null;
  lohnkosten?: string | null;
  materialkosten?: string | null;
  bemerkung?: string | null;
  wiederbeschaffungsdauer?: string | null;
  reparaturdauer?: string | null;
  korrekturWiederbeschaffungswert?: string | null;
  versandinformation?: Array<Versandinformation | null> | null;
};

export type Stundenverrechnungssaetze = {
  typ?: string | null;
  mechanik?: string | null;
  karosserie?: string | null;
  lackierung?: string | null;
  elektrik?: string | null;
  aufschlagLackmaterial?: number | null;
  aufschlagErsatzteil?: number | null;
  verbringungszeit?: number | null;
  lackart?: string | null;
  inklusiveMaterial?: boolean | null;
  lacquerMethod?: string | null;
};

export type Unfallgegner = {
  personId?: string | null;
};

export type Unfalldaten = {
  land?: string | null;
  unfallort?: string | null;
  datum?: Datum;
  kennzeichen?: string | null;
  fahrzeughersteller?: string | null;
  fahrzeugart?: string | null;
  sonstigeFahrzeugart?: string | null;
  versicherungId?: string | null;
  versicherungsnummer?: string | null;
  schadennummer?: string | null;
  selbstbeteiligung?: string | null;
};

export type CalculationDocument = {
  key?: string | null;
  dateiname?: string | null;
  uploaddatum?: string | null;
};

export type DAT = {
  dossierId?: string | null;
  contractId?: string | null;
  datECode?: string | null;
  letzteAktualisierung?: string | null;
  calculationDocument?: CalculationDocument | null;
  vehicleEvaluationDocument?: CalculationDocument | null;
  arbeitswerte?: string | null;
  wertverbesserung?: number | null;
  reparaturkostenBrutto?: number | null;
  lohnkosten?: number | null;
  materialkosten?: number | null;
  anzahlSitze?: number | null;
  anzahlTueren?: number | null;
  antriebsart?: string | null;
  aufbauart?: string | null;
  hubraum?: number | null;
  leistungKw?: number | null;
  farbe?: string | null;
  kennzeichen?: string | null;
  kilometerleistung?: string | null;
  fahrgestellnummer?: string | null;
  wiederbeschaffungswertBrutto?: number | null;
  listenneupreisBrutto?: number | null;
  listenausstattungspreisBrutto?: number | null;
  neupreisBrutto?: string | null;
  manufacturer?: number | null;
  vehicleType?: number | null;
  baseModel?: number | null;
  paintMethod?: string | null;
};

export type Reparaturdauer = {
  arbeitswerteProStunde?: string | null;
  materialbeschaffungsdauer?: string | null;
};

export type Unterschrift = {
  key?: string;
  datum?: string;
  unterzeichner?: Unterzeichner;
};

export type Unterschriften = {
  dsgvo?: Unterschrift;
  abtretungserklaerung?: Unterschrift;
  his?: Unterschrift;
  schlussbemerkung?: Unterschrift;
  auftragsbestaetigung?: Unterschrift;
};

export type Foto = {
  key?: string;
  dateiname?: string;
  kategorien?: Array<string> | null;
  typ?: Dateityp;
  thumbnailname?: string | null;
  thumbnailkey?: string | null;
  uploaddatum?: string;
  beschreibung?: string | null;
};

export type Datei = {
  key?: string;
  dateiname?: string;
  kategorien?: Array<string> | null;
  typ?: Dateityp;
  uploaddatum?: string;
  restwertangebot?: boolean | null;
  wert?: number | null;
};

export type BemerkungenZumSchaden = {
  fahrbereit?: boolean | null;
  verkehrssicherNachNotreparatur?: boolean | null;
  verkehrssicher?: boolean | null;
  pruefarbeitenErforderlich?: boolean | null;
  achsvermessungErforderlich?: boolean | null;
  achsvermessungDurchgefuehrt?: boolean | null;
  achsvermessungMitSchadenfeststellung?: boolean | null;
  lenkgetriebeErneuern?: boolean | null;
  achsweiseErneuerungDerReifen?: boolean | null;
  achsweiseErneuerungDerStossdaempfer?: boolean | null;
  karosserievermessungErforderlich?: boolean | null;
  richtbankErforderlich?: boolean | null;
  beilackierungNotwendig?: boolean | null;
  anhaengerkupplungAusSicherheitsgruendenErneuern?: boolean | null;
  schadenhergang?: string | null;
  plausibilitaet?: string | null;
  factoring?: boolean | null;
  finanzierungMoeglich?: boolean | null;
  reparaturnachweisAuswahl?: string | null;
  probefahrtErforderlich?: boolean | null;
  fehlerspeicherAuslesen?: boolean | null;
  bemerkungen?: string | null;
};

export type Gutachten = {
  gutachtennummer?: string | null;
  key?: string | null;
  dateiname?: string | null;
  keyDruckversion?: string | null;
  dateinameDruckversion?: string | null;
  erstellungsdatum?: string;
  generierungsstatus?: Generierungsstatus | null;
  generierungsfehler?: string | null;
};

export enum Regel {
  IMMER = 'IMMER',
  NACHBESICHTIGUNG = 'NACHBESICHTIGUNG',
  REPARATURKOSTEN_GROESSER_WIEDERBESCHAFFUNGSWERT = 'REPARATURKOSTEN_GROESSER_WIEDERBESCHAFFUNGSWERT',
  REPARATURKOSTEN_KLEINER_WIEDERBESCHAFFUNGSWERT = 'REPARATURKOSTEN_KLEINER_WIEDERBESCHAFFUNGSWERT',
  REPARATURWUERDIG = 'REPARATURWUERDIG',
  REPARATURGRAENZE = 'REPARATURGRAENZE',
  AELTER_ALS_3_JAHRE_UND_SCHECKHEFT = 'AELTER_ALS_3_JAHRE_UND_SCHECKHEFT',
  AELTER_ALS_3_JAHRE_UND_NICHT_SCHECKHEFT = 'AELTER_ALS_3_JAHRE_UND_NICHT_SCHECKHEFT',
  FAHRZEUG_JUENGER_ALS_3_JAHRE = 'FAHRZEUG_JUENGER_ALS_3_JAHRE',
  REPARATURKOSTEN_KLEINER_50PROZENT_WIEDERBESCHAFFUNGSWERT = 'REPARATURKOSTEN_KLEINER_50PROZENT_WIEDERBESCHAFFUNGSWERT',
  REPARATURWERKSTATT_ANGEGEBEN = 'REPARATURWERKSTATT_ANGEGEBEN',
  REPARATURKOSTEN_GROESSER_50PROZENT_WIEDERBESCHAFFUNGSWERT = 'REPARATURKOSTEN_GROESSER_50PROZENT_WIEDERBESCHAFFUNGSWERT',
  FAHRZEUGALTER_GROESSER_120_MONATE = 'FAHRZEUGALTER_GROESSER_120_MONATE',
  FAHRZEUGALTER_KLEINER_120_MONATE = 'FAHRZEUGALTER_KLEINER_120_MONATE',
  MARKIERUNG_FAHRZEUGGRAFIK_IN_PROEXPERT = 'MARKIERUNG_FAHRZEUGGRAFIK_IN_PROEXPERT',
  MERKANTILER_MINDERWERT_VORHANDEN = 'MERKANTILER_MINDERWERT_VORHANDEN',
  MERKANTILER_MINDERWERT_KLEINER_100_EURO = 'MERKANTILER_MINDERWERT_KLEINER_100_EURO',
  MERKANTILER_MINDERWERT_NICHT_VORHANDEN_FAHRZEUG_AELTER_10_JAHRE = 'MERKANTILER_MINDERWERT_NICHT_VORHANDEN_FAHRZEUG_AELTER_10_JAHRE',
  RICHTBANK_ERFORDERLICH = 'RICHTBANK_ERFORDERLICH',
  VERMESSUNG_VORDERACHSE = 'VERMESSUNG_VORDERACHSE',
  PRUEFARBEITEN_ERFORDERLICH = 'PRUEFARBEITEN_ERFORDERLICH',
  FAHRBEREIT = 'FAHRBEREIT',
  VERKEHRSSICHERHEIT = 'VERKEHRSSICHERHEIT',
  VERKEHRSSICHER_NACH_NOTREPARATUR = 'VERKEHRSSICHER_NACH_NOTREPARATUR',
  ZULASSUNGSBESCHEINIGUNG_TEIL_1 = 'ZULASSUNGSBESCHEINIGUNG_TEIL_1',
  ZULASSUNGSBESCHEINIGUNG_TEIL_2 = 'ZULASSUNGSBESCHEINIGUNG_TEIL_2',
  DURCHGEFUEHRTE_ACHSVERMESSUNG_MIT_SCHADENSFESTSTELLUNG = 'DURCHGEFUEHRTE_ACHSVERMESSUNG_MIT_SCHADENSFESTSTELLUNG',
  DURCHGEFUEHRTE_ACHSVERMESSUNG_OHNE_SCHADENSFESTSTELLUNG = 'DURCHGEFUEHRTE_ACHSVERMESSUNG_OHNE_SCHADENSFESTSTELLUNG',
  BEILACKIERUNG_ERFORDERLICH = 'BEILACKIERUNG_ERFORDERLICH',
  ACHSWEISE_ERNEUERUNG_DER_REIFEN = 'ACHSWEISE_ERNEUERUNG_DER_REIFEN',
  LENKGETRIEBE_ERNEUERN = 'LENKGETRIEBE_ERNEUERN',
  REPARATURKOSTEN_GROESSER_200_PROZENT_WIEDERBESCHAFFUNGSWERT_KEIN_RESTWERT = 'REPARATURKOSTEN_GROESSER_200_PROZENT_WIEDERBESCHAFFUNGSWERT_KEIN_RESTWERT',
  WERTVERBESSERUNG = 'WERTVERBESSERUNG',
  LACKSCHICHTENDICKENMESSUNG = 'LACKSCHICHTENDICKENMESSUNG',
  KAROSSERIEVERMESSUNG = 'KAROSSERIEVERMESSUNG',
  ANHAENGERKUPPLUNG = 'ANHAENGERKUPPLUNG',
  FAHRZEUGZUSTAND = 'FAHRZEUGZUSTAND',
  ACHSWEISE_ERNEUERUNG_DER_STOSSDAEMPFER = 'ACHSWEISE_ERNEUERUNG_DER_STOSSDAEMPFER',
  MIETWAGENKLASSE_VORHANDEN = 'MIETWAGENKLASSE_VORHANDEN',
  NUTZUNGSAUSFALLKLASSE_VORHANDEN = 'NUTZUNGSAUSFALLKLASSE_VORHANDEN',
  NICHT_FAHRBEREIT = 'NICHT_FAHRBEREIT',
  PROBEFAHRT_ERFORDERLICH = 'PROBEFAHRT_ERFORDERLICH',
  FEHLERSPEICHER_AUSLESEN = 'FEHLERSPEICHER_AUSLESEN',
  ALTSCHAEDEN_ODER_VORSCHAEDEN_VORHANDEN = 'ALTSCHAEDEN_ODER_VORSCHAEDEN_VORHANDEN',
  KORREKTUR_DURCH_VORSCHAEDEN_GROESSER_0 = 'KORREKTUR_DURCH_VORSCHAEDEN_GROESSER_0',
  REGELBESTEUERUNG_JA = 'REGELBESTEUERUNG_JA'
}

export type VorgangTextbaustein = {
  ueberschrift: string;
  text: string;
  regel: Regel;
  reihenfolge: number;
  disabled: boolean;
  id?: string | null;
};

export type His = {
  datei?: CalculationDocument;
  wurdeHisVersand?: boolean | null;
  versandDatum?: string | null;
};

export type EmailEmpfaenger = {
  emailAdresse: string;
  typ: EmailEmpfaengerTyp;
};

export type HonorarEintrag = {
  schadenhoehe?: number | null;
  grundhonorar?: number | null;
};

export type Mandant = {
  id?: string | null;
  hisEmail?: string | null;
  honorartabelle?: Array<HonorarEintrag> | null;
  updatedAt?: string | null;
  createdAt?: string | null;
};

export type Unternehmen = {
  id?: string | null;
  userId?: string | null;
  gruppen?: Array<string | null> | null;
  typ?: UnternehmenTyp;
  firmenname?: string | null;
  anrede?: string | null;
  nachname?: string | null;
  vorname?: string | null;
  strasse?: string | null;
  plz?: string | null;
  ort?: string | null;
  telefon?: Array<string | null> | null;
  email?: Array<string | null> | null;
  updatedAt?: string | null;
  createdAt?: string | null;
};

export type Mandantenrechte = {
  mandantenKuerzel: string;
  rechte?: Array<string> | null;
};

export type UserRechte = {
  id?: string;
  mandantenrechte?: Array<Mandantenrechte> | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type Textbaustein = {
  id?: string | null;
  mandant: string;
  gutachtenart: Gutachtenart;
  ueberschrift: string;
  text: string;
  regel: Regel;
  reihenfolge: number;
  createdAt?: string;
  updatedAt?: string;
};

export type Event = {
  userId?: string | null;
  gruppen?: Array<string> | null;
  vorgangId?: string | null;
  type?: string;
  payload?: object;
} & PersistedEntity;

export type SuchePersonenResponse = {
  items: Person[];
  total: number;
  fehler?: string;
};

export type VorgangDashboardFoto = {
  thumbnailkey?: string | null;
};

export type SuchePersonenArgs = {
  suchbegriff: string;
  mandant: string;
  typ: string;
  from?: number;
  limit?: number;
  sortBy?: string;
  order?: 'asc' | 'desc';
};

export type SucheUnternehmenArgs = {
  suchbegriff: string;
  typ: string;
  from?: number;
  limit?: number;
  sortBy?: string;
  order?: 'asc' | 'desc';
};

export type SucheUnternehmenResponse = {
  items: Unternehmen[];
  total: number;
  fehler?: string;
};

export type GutachtenMitUrl = {
  gutachtennummer: string;
  url: string;
  erstellungsdatum: string;
};

export type GutachtenMitUrlResponse = {
  items: GutachtenMitUrl[];
};

export enum Fahrzeugtyp {
  ZWEIRAEDRIG = 'ZWEIRAEDRIG',
  MEHRRAEDRIG = 'MEHRRAEDRIG'
}

export type Fahrzeugdaten = {
  fahrgestellnummer?: string | null;
  kilometerleistung?: string | null;
  kilometerleistungGeschaetzt?: string | null;
  kilometerleistungAngegeben?: string | null;
  land?: string | null;
  kennzeichen?: string | null;
  erstesZulassungsdatum?: Datum;
  letztesZulassungsdatum?: Datum;
  naechsteHauptuntersuchung?: Datum;
  anzahlVorhalter?: string | null;
  scheckheftgepflegt?: boolean | null;
  regelbesteuert?: boolean | null;
  bereifung?: string | null;
  fahrzeugtyp?: Fahrzeugtyp | null;
  achsen?: Array<Achse> | null;
  allgemeinzustand?: string | null;
  altschaden?: Altschaden;
  reparierteVorschaeden?: ReparierteVorschaden;
  bemerkungen?: string | null;
  zulassungsbescheinigung?: string | null;
  reifenzustand?: string | null;
  mietwagenklasse?: string | null;
  nutzungsausfallKlasse?: string | null;
  nutzungsausfallKlasseOhneFahrzeugAlter?: string | null;
  nutzungsausfallOhneAltersbezug?: boolean | null;
  phantomkalkulation?: boolean | null;
  anzahlSitze?: string | null;
  anzahlTueren?: string | null;
  antriebsart?: string | null;
  aufbauart?: string | null;
  hubraum?: string | null;
  leistungKw?: string | null;
  farbe?: string | null;
  container?: string | null;
  containerName?: string | null;
  herstellername?: string | null;
  haupttypname?: string | null;
  untertypname?: string | null;
  fahrzeugartname?: string | null;
  serienausstattung?: Array<string | null> | null;
  sonderausstattung?: Array<string | null> | null;
  zusatzausstattung?: Array<string | null> | null;
  reparierteSchadenbereiche?: Array<ReparierterSchadenbereich | null> | null;
  lackPositionen?: Array<LackPosition | null> | null;
  arbeitsPositionen?: Array<ArbeitsPosition | null> | null;
  materialPositionen?: Array<MaterialPosition | null> | null;
};

export type VorgangDashboard = {
  id: string;
  mandant: string;
  vorgangsnummer: string;
  vorgangsnummerRaw: string;
  status: string;
  userId: string;
  bearbeiterVorname: string;
  bearbeiterNachname: string;
  gruppen: Array<string>;
  ehemaligeBearbeiter: Array<string>;
  gutachtenart: string;
  kennzeichen?: string;
  fahrgestellnummer?: string;
  fotos: Array<VorgangDashboardFoto>;
  rechnungsnummern: Array<string>;
  verwaltungscheckbox: boolean;
  geloescht: boolean;
  auftraggeberFirmenname?: string;
  auftraggeberNachname?: string;
  auftraggeberAnzeigename?: string;
  fahrzeughalterVorname?: string;
  fahrzeughalterNachname?: string;
  gutachten: Array<Gutachten>;
  updatedAt: string;
  createdAt: string;
};

export type SucheVorgangResponse = {
  items: VorgangDashboard[];
  total: number;
  totalAktive: number;
  totalQualitaetssicherung: number;
  totalBeweissicherung: number;
  totalMahnwesen: number;
  totalGeschlossen: number;
  fehler?: string;
};

export type Zahlungseingang = {
  id: string;
  jahr: string;
  importDatum: string;
  rechnungsnummer: string;
  betrag: number;
  bezahldatum: string;
  status: string;
  name: string;
  beschreibung: string;
  gutachtennummer: string;
};

export type Vorgang = {
  id: string;
  vorgangsnummer: string;
  mandant: string;
  status: Status;
  statusLetzteAenderung: string;
  aufgaben: Aufgaben;
  userId: string;
  bearbeiter: deprecated_Benutzer;
  gruppen: Array<string>;
  ehemaligeBearbeiter: Array<string>;
  gutachtenart: Gutachtenart;
  readonly besichtigungen: BesichtigungenConnection;
  auftraggeber: Person | null;
  auftraggeberId: string | null;
  auftraggeberRechtsschutzVorhanden: boolean | null;
  auftraggeberVorsteuerabzugsberechtigt: boolean | null;
  auftraggeberPostversand: boolean | null;
  auftraggeberVip: boolean | null;
  fahrzeughalterId: string | null;
  fahrzeughalterIstAuftraggeber: boolean;
  fahrzeughalterRechtsschutzVorhanden: boolean | null;
  fahrzeughalter: Person | null;
  fahrzeugdaten: Fahrzeugdaten;
  bewertung?: Bewertung;
  stundenverrechnungssaetze?: Stundenverrechnungssaetze;
  unfallgegnerId?: string | null;
  unfallgegner?: Person | null;
  unfalldaten: Unfalldaten;
  dat?: DAT;
  reparaturdauer?: Reparaturdauer;
  unterschriften?: Unterschriften;
  fotos?: Array<Foto> | null;
  dateien?: Array<Datei> | null;
  bemerkungenZumSchaden?: BemerkungenZumSchaden;
  gutachten?: Array<Gutachten> | null;
  rechtsanwaltId?: string | null;
  rechtsanwalt?: Unternehmen | null;
  werkstattId?: string | null;
  werkstatt?: Unternehmen | null;
  entsorgen?: boolean | null;
  vorgangTextbausteine?: Array<VorgangTextbaustein | null> | null;
  wurdeGutachtenVersandt?: boolean | null;
  his?: His;
  zugehoerigeGutachtenId?: string | null;
  zugehoerigeGutachtenNummer?: string | null;
  rechnungsnummern?: Array<string | null> | null;
  versandinformation?: Array<Versandinformation | null> | null;
  verwaltungscheckbox?: boolean | null;
  geloescht?: boolean | null;
  updatedAt: string;
  createdAt: string;
  createdAtYear?: string | null;
  versicherungId?: string | null;
  versicherung?: Unternehmen | null;
  zahlungseingang?: Array<Zahlungseingang>;
};

export type AktualisiereDATDatenRequest = {
  vorgangId: string;
  contractId: string;
  datECode: string;
};

export type AktualisiereDATDatenResponse = {
  ok: boolean;
  error?: string;
};

export type SftpConnectorParams = {
  connectorId: string;
  remotePath: string;
  s3Key: string;
  bucket: string;
};

export type EmailAnhang = {
  filename: string;
  content: string | undefined;
  link: string;
  inline: boolean;
  bytes: number;
  encoding?: string;
};

export type ZahlungsimportZeile = {
  id?: string;
  vorgangId?: string;
  gutachtennummer: string;
  rechnungsnummer: string;
  betrag: number | null;
  bezahldatum: string;
  beschreibung: string;
  name: string;
  status: string;
  fehler: string | null;
  hinweis: string | null;
};

export interface Zahlungsimport {
  zeilen: ZahlungsimportZeile[];
}

export type ZahlungseingangHistorie = {
  id: string | undefined;
  ersteller: string;
  datei: string;
  importDatum: string;
  importErgebnis: Zahlungsimport;
};

export interface AppSyncResolverEvent<TArgument = never> {
  arguments: TArgument;
  identity: {
    sub: string;
    username: string;
    groups: string[];
  };
  source?: {
    userId: string;
  } | null;
}

export interface Context {
  functionName: string;
}

export type PaginierteListe<T> = { items: T[]; nextToken?: Record<string, unknown>; nextTokenEncoded?: string | null };

export type HttpMethods = 'GET' | 'POST' | 'PATCH' | 'DELETE' | 'HEAD';

export type ApiRequest<C = any> = {
  url: string;
  method: HttpMethods;
  headers?: Record<string, string>;
  body?: string | object;
  context?: C;
};

export type DatResponse<T> = {
  ok: boolean;
  data?: T;
  error?: string;
};

export type UserId = string;
export type Username = string;
